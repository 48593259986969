<template>
  <div class="deviceView">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "deviceView",
  data() {
    return {
      barIndex: 0,
      barList: [
        {
          svg: "shouye",
          name: "首页",
          url: "deviceBorrow/homeBorrow",
          query: {},
        },
        {
          svg: "I",
          name: "我的",
          url: "deviceBorrow/deviceMine",
          query: {},
        },
      ],
    };
  },
  beforeDestroy() {},
  mounted() {
    this.barIndex = 0;
    this.$bus.$emit("showBottomStatus", { list: this.barList });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceView {
  width: 100%;
  height: 100%;

  .subView {
    width: 100%;
    height: 100%;
  }
}
</style>
